import axios from 'axios';
import request from '@/utils/api';
import { userStore } from '@/store';

// 都道府県マスタ取得
export const getPrefectures = (): any => {
  return request({
    url: '/api/v1/master/prefecture',
    method: 'get'
  });
};

export const getCategories = (): any => {
  return request({
    url: '/api/v1/master/category',
    method: 'get'
  });
};

export const getUsers = (): any => {
  return request({
    url: '/api/v1/user/me',
    method: 'get'
  });
};

// Pendoのaccount情報取得
export const getPendoAccount = (): any => {
  const xbaUrl = localStorage.getItem('xba');
  const token = localStorage.getItem('auth._token.bamidp') || '';

  if (
    !token ||
    token === 'false' ||
    !xbaUrl ||
    !userStore.loginUser.company_unique_code
  ) {
    return null;
  }

  return axios({
    method: 'get',
    url:
      xbaUrl +
      `/api/v1/company/pendo?company_unique_code=${userStore.loginUser.company_unique_code}`,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
      Authorization: token
    }
  }).catch(
    function () {
      console.log("XBAのAPIを呼び出す時、エラーが発生しました。");
    }
  );
};
