import type { Plugin } from '@nuxt/types';
import { truncate, formatDate } from '@/utils/filter';

type truncate = (
  value: string | null,
  specified_length?: any,
  specified_omission?: string
) => string;
type formatDate = (value: string | null) => string;

// javascript内で使うときは定義する
declare module '@nuxt/types' {
  interface Context {
    $truncate: truncate;
    $formatDate: formatDate;
  }
}

const fillterPlugin: Plugin = (_context, inject) => {
  inject('truncate', truncate);
  inject('formatDate', formatDate);
};

export default fillterPlugin;
