













import { defineComponent, reactive } from '@nuxtjs/composition-api';

export default defineComponent({
  layout: 'plain',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup(props: { error: { statusCode: number } }) {
    const data = reactive<any>({
      error: {
        warn: '',
        message: ''
      }
    });

    // エラーメッセージを追加
    const addErrorMessage = (warn: string, message: string) => {
      data.error.warn = warn;
      data.error.message = message;
    };

    switch (props.error.statusCode) {
      case 403:
        addErrorMessage(
          '403 Forbidden',
          'アクセスが許可されていませんでした。'
        );
        break;
      case 404:
        addErrorMessage(
          '404 Not Found',
          'ページが見つかりませんでした。'
        );
        break;
      case 405:
        addErrorMessage(
          '405 Method Not Allowed',
          'メソッドが許可されていませんでした。'
        );
        break;
      case 422:
        addErrorMessage(
          '422 Unprocessable entity',
          'バリデーションエラーが発生しました。'
        );
        break;
      case 500:
        addErrorMessage(
          '500 Internal Server Error',
          'エラーが発生しました。'
        );
        break;
    }

    return {
      data
    };
  }
});
