// マスターデータストア
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import {
  IPrefectures,
  ICategories,
  IAppConfig,
  IUsers,
  IPendoAccount
} from '@/types/master';
import {
  getPrefectures,
  getCategories,
  getUsers,
  getPendoAccount
} from '@/apis/master';

export interface State {
  prefectures: IPrefectures[];
  categories: ICategories[];
  appConfig: IAppConfig;
  users: IUsers[];
  showHeader: boolean;
}

@Module({ stateFactory: true, namespaced: true, name: 'master' })
export default class Master extends VuexModule implements State {
  // 都道府県マスタ
  prefectures: IPrefectures[] = [
    {
      pref_code: 0,
      pref_name: ''
    }
  ];

  // カテゴリマスタ
  categories: ICategories[] = [
    {
      category_code: '',
      category_description: '',
      category_name: '',
      category_name_code: '',
      is_disabled: 0
    }
  ];

  appConfig: IAppConfig = {
    name: '',
    url: '',
    is_yappli_client: false
  };

  users: IUsers[] = [
    {
      app_config: this.appConfig,
      user: {}
    }
  ];

  // Pendoのaccount情報
  pendoAccount: IPendoAccount = {
    id: '',
    type: '',
    industry_01: '',
    industry_02: '',
    industry_03: '',
    company_id: 0,
    company_created_at: '',
    company_is_charge_target: '',
    company_charge_amount: 0,
    company_charge_start_at: '',
    company_employee_count: 0,
    company_matching_count: 0,
    company_interesting_features: '',
    homepage: ''
  };

  showHeader = false;

  @Mutation
  public SET_PREFECTURES(items: IPrefectures[]): void {
    this.prefectures = items;
  }

  @Mutation
  public SET_CATEGORIES(items: ICategories[]): void {
    this.categories = items;
  }

  @Mutation
  public SET_USERS(items: IUsers[]): void {
    this.users = items;
  }

  @Mutation
  public SET_SHOW_HEADER(vol: boolean): void {
    this.showHeader = vol;
  }

  @Mutation
  public SET_PENDO_ACCOUNT(item: IPendoAccount): void {
    this.pendoAccount = item;
  }

  @Action({ rawError: true })
  loadMaster(): Promise<boolean> {
    const promisePrefectures: any = getPrefectures();
    const promiseCategories: any = getCategories();
    const promiseUsers: any = getUsers();
    const promisePendoAccount: any = getPendoAccount();

    return Promise.all([
      promisePrefectures,
      promiseCategories,
      promiseUsers,
      promisePendoAccount
    ]).then(([prefectures, categories, users, pendoAccount]) => {
      if (prefectures) {
        this.SET_PREFECTURES(prefectures.result.list);
      }
      if (categories) {
        this.SET_CATEGORIES(categories.result.list);
      }
      if (users) {
        this.SET_USERS(users.result);
      }
      if (pendoAccount && pendoAccount.data.result) {
        this.SET_PENDO_ACCOUNT(pendoAccount.data.result);
      }
      return true;
    });
  }
}
