// ユーザ情報ストア
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';

import { ILoginUser } from '@/types/user';
import { getUserMe } from '@/apis/user';

export interface State {
  loginUser: ILoginUser;
}

/**
 * ユーザ情報をストアへ登録
 *
 * @export
 * @class User
 * @extends {VuexModule}
 * @implements {State}
 */
@Module({ stateFactory: true, namespaced: true, name: 'user' })
export default class User extends VuexModule implements State {
  loginUser: ILoginUser = {
    bb_code: '',
    bb_id: null,
    bb_name: null,
    company_corporate_number: '',
    company_enable_ba: '',
    company_enable_baportal: null,
    company_id: 0,
    company_is_disabled: null,
    company_is_unsubscribed: 0,
    company_logo_file: null,
    company_name: '',
    company_type: '',
    company_unique_code: '',
    user_bb_name: null,
    user_branche_codes: null,
    user_branches: null,
    user_created_at: '',
    user_email: '',
    user_email_alt: null,
    user_first_name: '',
    user_first_name_kana: '',
    user_id: 0,
    user_is_disabled: 0,
    user_is_super_user: null,
    user_last_name: '',
    user_last_name_kana: '',
    user_login_id: '',
    user_name: '',
    user_profile_picture: null,
    user_restricts_companies_under_my_branch: 0,
    user_role: 0,
    user_tel: '',
    user_type: '',
    user_type_group: '',
    user_type_name: '',
    user_unique_code: '',
    user_updated_at: ''
  };

  @Mutation
  public SET_USER_ME_VALUE(item: ILoginUser): void {
    this.loginUser = item;
  }

  /**
   * ユーザ情報APIを並列で実行しストア登録を行う
   *
   * @return {boolean}  {Promise<boolean>}
   * @memberof User
   */
  @Action({ rawError: true })
  loadUser(): Promise<boolean> {
    const promiseUserMe: any = getUserMe();

    return Promise.all([promiseUserMe]).then(([user]) => {
      if (user && user.data.result) {
        this.SET_USER_ME_VALUE(user.data.result.user);
      }
      return true;
    });
  }
}
