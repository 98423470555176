/**
 * 位置情報取得処理
 *
 * @return Promise<GeolocationPosition> 位置情報取得結果
 */
export const getCurrentLocation = (): Promise<GeolocationPosition> => {
  const promise = new Promise<GeolocationPosition>(
    (resolve, reject) => {
      if('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (success) => {
            resolve(success);
          },
          (error) => {
            reject(error);
          }, {
            maximumAge: 0
          }
        );
      } else {
        const error = 'ご使用の端末は位置情報取得をサポートしていません';
        reject(error);
      }
    }
  );
  return promise;
};
