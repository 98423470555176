import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2f9aca29 = () => interopDefault(import('../pages/ba.vue' /* webpackChunkName: "pages/ba" */))
const _442485af = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _199ef1cf = () => interopDefault(import('../pages/health_check.vue' /* webpackChunkName: "pages/health_check" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _49c57cf4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _61b5e5e0 = () => interopDefault(import('../pages/coupon/detail.vue' /* webpackChunkName: "pages/coupon/detail" */))
const _1ec2016d = () => interopDefault(import('../pages/coupon/list.vue' /* webpackChunkName: "pages/coupon/list" */))
const _29edbee8 = () => interopDefault(import('../pages/coupon/near-current-location-list.vue' /* webpackChunkName: "pages/coupon/near-current-location-list" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ba",
    component: _2f9aca29,
    name: "ba"
  }, {
    path: "/callback",
    component: _442485af,
    name: "callback"
  }, {
    path: "/health_check",
    component: _199ef1cf,
    name: "health_check"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/logout",
    component: _49c57cf4,
    name: "logout"
  }, {
    path: "/coupon/detail",
    component: _61b5e5e0,
    name: "coupon-detail"
  }, {
    path: "/coupon/list",
    component: _1ec2016d,
    name: "coupon-list"
  }, {
    path: "/coupon/near-current-location-list",
    component: _29edbee8,
    name: "coupon-near-current-location-list"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
