












import {
  defineComponent,
  ref,
  useContext,
  useMeta,
  watch,
  useRoute
} from '@nuxtjs/composition-api';
import { masterStore, userStore, bankStore } from '@/store';
import {
  getGoogleTagMangerHeaderSetting,
  getGoogleTagMangerBodySetting,
  getPendoHeaderSetting
} from '@/utils';

export default defineComponent({
  middleware: 'authentication',
  setup() {
    const urlParams = new URLSearchParams(window.location.search);
    const xba = urlParams.get('xba');
    if (xba) {
      localStorage.setItem('xba', xba);
    }

    const initialized = ref<boolean>(false);
    const ctx = useContext();
    if (ctx.$auth && ctx.$auth.loggedIn) {
      // 共通データの取得
      Promise.all([userStore.loadUser()])
        .then(() => {
          masterStore.loadMaster().then(() => {
            // Pendo headerのスクリプト設定
            if (masterStore.pendoAccount.id) {
              const pendoHeader = getPendoHeaderSetting();
              script.value = [...script.value, ...pendoHeader];
            }
            initialized.value = true;
          });
        })
        .catch((err: any) => {
          ctx.error({
            statusCode: err.status
          });
        });
    }

    const route = useRoute();
    const gaId = ref<string>('');
    gaId.value = process.env.GA_ID;
    const gtmId = ref<string>('');
    gtmId.value = process.env.GTM_ID;
    const { script } = useMeta();
    const gtmBody = ref<string>('');

    watch(
      () => route.value,
      () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (GAのコードのため無視)
        if (gaId.value && typeof gtag === 'function') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore (GAのコードのため無視)
          gtag('config', gaId.value, { page_path: route.value.path });
        }
      }
    );

    // gtm headerのスクリプト設定
    script.value = getGoogleTagMangerHeaderSetting(gaId.value, gtmId.value);
    // gtm bodyの設定
    gtmBody.value = getGoogleTagMangerBodySetting(gtmId.value);

    return {
      masterStore,
      initialized,
      gtmBody
    };
  },
  head: {}
});
