import { masterStore, userStore } from '@/store';

/**
 * google tag managerのヘッダーの設定取得
 */
export const getGoogleTagMangerHeaderSetting = (
  gaId: string,
  gtmId: string
): any => {
  const script = [];
  if (gaId && process.env.ENABLE_GOOGLE_ANALYTICS_TAG) {
    script.push(
      {
        async: true,
        src: `https://www.googletagmanager.com/gtag/js?id=${gaId}`
      },
      {
        innerHTML: `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${gaId}');
        `
      }
    );
  }

  if (gtmId) {
    script.push({
      innerHTML: `
      (
        function(w,d,s,l,i){
          w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        }
      )(window,document,'script','dataLayer','${gtmId}');`
    });
  }
  return script;
};

/**
 * google tag managerのボディの設定取得
 */
export const getGoogleTagMangerBodySetting = (gtmId: string): any => {
  return gtmId
    ? `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
    : '';
};

/**
 * Pendoのヘッダーの設定取得
 */
export const getPendoHeaderSetting = (): any => {
  const script = [];
  const id = masterStore.pendoAccount.id
    ? `'${masterStore.pendoAccount.id}'`
    : null;
  const type = masterStore.pendoAccount.type
    ? `'${masterStore.pendoAccount.type}'`
    : null;
  const industry_01 = masterStore.pendoAccount.industry_01
    ? `'${masterStore.pendoAccount.industry_01}'`
    : null;
  const industry_02 = masterStore.pendoAccount.industry_02
    ? `'${masterStore.pendoAccount.industry_02}'`
    : null;
  const industry_03 = masterStore.pendoAccount.industry_03
    ? `'${masterStore.pendoAccount.industry_03}'`
    : null;
  const company_created_at = masterStore.pendoAccount.company_created_at
    ? `'${masterStore.pendoAccount.company_created_at}'`
    : null;
  const company_is_charge_target = masterStore.pendoAccount
    .company_is_charge_target
    ? `'${masterStore.pendoAccount.company_is_charge_target}'`
    : null;
  const company_charge_start_at = masterStore.pendoAccount
    .company_charge_start_at
    ? `'${masterStore.pendoAccount.company_charge_start_at}'`
    : null;
  const company_interesting_features = masterStore.pendoAccount
    .company_interesting_features
    ? `'${masterStore.pendoAccount.company_interesting_features}'`
    : null;
  const homepage = masterStore.pendoAccount.homepage
    ? `'${masterStore.pendoAccount.homepage}'`
    : null;
  const pendoHead = `
    (function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    
            pendo.initialize({
                visitor: {
                    id:                           '${userStore.loginUser.user_unique_code}',
                    role:                         '${userStore.loginUser.user_type_name}'
                },
                account: {
                    id:                           ${id},
                    type:                         ${type},
                    industry_01:                  ${industry_01},
                    industry_02:                  ${industry_02},
                    industry_03:                  ${industry_03},
                    company_id:                   ${masterStore.pendoAccount.company_id},
                    company_created_at:           ${company_created_at},
                    company_is_charge_target:     ${company_is_charge_target},
                    company_charge_amount:        ${masterStore.pendoAccount.company_charge_amount},
                    company_charge_start_at:      ${company_charge_start_at},
                    company_employee_count:       ${masterStore.pendoAccount.company_employee_count},
                    company_matching_count:       ${masterStore.pendoAccount.company_matching_count},
                    company_interesting_features: ${company_interesting_features},
                    homepage:                     ${homepage},
                }
            });
    })('${process.env.PENDO_API_KEY}');
  `;
  script.push({
    innerHTML: pendoHead
  });
  return script;
};
