// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/arrow_right.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".swiper-container{background:#efefef}.swiper-button-prev,.swiper-container-rtl .swiper-button-next{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");transform:rotate(-180deg);left:0}.swiper-button-next,.swiper-container-rtl .swiper-button-prev{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");right:0}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
