/* eslint-disable */

export default async function ({ $auth, redirect, store, app }) {
  if (!$auth.loggedIn) {
    const logout = localStorage.getItem('logout');
    if (logout) {
      localStorage.clear();
      window.location.replace('/ba');
    } else {
      location.href = `/login`;
    }
  }
}
