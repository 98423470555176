import {Store} from 'vuex';
import {getModule} from 'vuex-module-decorators';
import Master from '@/store/master';
import User from '@/store/user';

// eslint-disable-next-line import/no-mutable-exports
let masterStore: Master;
// eslint-disable-next-line import/no-mutable-exports
let userStore: User;

/**
 * storeの初期化
 *
 * @param {Store<any>} store ストア
 * @see {@link https://typescript.nuxtjs.org/ja/cookbook/store/} vuex-module-decorators
 */
function initialiseStores(store: Store<any>): void {
  masterStore = getModule(Master, store);
  userStore = getModule(User, store);
}

export {initialiseStores, masterStore, userStore};
