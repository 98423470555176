import dayjs from 'dayjs';
/**
 * 文字列の長さ加工
 *
 * @param {any} value 対象文字列
 * @param {number} [specified_length] 返却する文字数
 * @param {string} [specified_omission] Suffix文字列
 * @returns {string} フォーマットされた文字列
 */
export const truncate = (
  value: any,
  specified_length?: any,
  specified_omission?: string
): string => {
  const length = specified_length ? parseInt(specified_length, 10) : 20;
  const ommision = specified_omission ? specified_omission.toString() : '...';
  if (value === undefined || value === null) {
    value = '';
  }

  if (value.length <= length) {
    return value;
  }

  return value.substring(0, length) + ommision;
};

/**
 * 日付のフォーマット
 *
 * @param {(string | null)} value 対象の文字列
 * @returns {string} フォーマットされた文字列
 */
export const formatDate = (value: string | null): string => {
  if (value === undefined || value === null || value === '') {
    return '';
  }
  return dayjs(value).format('YYYY年MM月DD日(ddd)');
};
