










import {
  defineComponent,
  useRoute,
  useMeta,
  ref,
  watch
} from '@nuxtjs/composition-api';
import {
  getGoogleTagMangerHeaderSetting,
  getGoogleTagMangerBodySetting
} from '@/utils';

export default defineComponent({
  setup() {
    const route = useRoute();
    const gaId = ref<string>('');
    gaId.value = process.env.GA_ID;
    const gtmId = ref<string>('');
    gtmId.value = process.env.GTM_ID;
    const { script } = useMeta();
    const gtmBody = ref<string>('');

    watch(
      () => route.value,
      () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (GAのコードのため無視)
        if (gaId.value && typeof gtag === 'function') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore (GAのコードのため無視)
          gtag('config', gaId.value, { page_path: route.value.path });
        }
      }
    );

    // gtm headerのスクリプト設定
    script.value = getGoogleTagMangerHeaderSetting(gaId.value, gtmId.value);
    // gtm bodyの設定
    gtmBody.value = getGoogleTagMangerBodySetting(gtmId.value);

    return {
      gtmBody
    };
  },
  head: {}
});
