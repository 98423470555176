


































































import {
  defineComponent,
  reactive,
  useRouter,
  useRoute,
  computed,
  toRefs,
  watch
} from '@nuxtjs/composition-api';
import {masterStore} from '@/store';
import {SearchForm} from '@/types';
import {getCurrentLocation} from "@/utils/location";

export default defineComponent({
  setup() {
    const form = reactive<SearchForm>({
      searchForm: {
        pref: '',
        category: '',
        keyword: '',
        latitude: '',
        longitude: ''
      }
    });
    const data = reactive<any>({
      menuActive: false,
      isYappliClient: false
    });

    // is_disabled=0でデータをフィルタリングする
    const categories = computed(() => {
      let _items = [...masterStore.categories];
      _items = _items.filter((x) => !x.is_disabled);
      masterStore.SET_CATEGORIES(_items);
      return _items;
    });

    data.isYappliClient =
      masterStore && masterStore.users && masterStore.users.app_config
        ? masterStore.users.app_config.is_yappli_client
        : false;

    // sp側のメインメニューの表示を制御します
    const menuToggle = (): void => {
      data.menuActive = !data.menuActive;
    };

    const router = useRouter();
    const search = (menu: string): void => {
      if (form.searchForm.pref === '現在地') {
        getCurrentLocation().then((value) => {
          form.searchForm.latitude = value.coords.latitude.toString();
          form.searchForm.longitude = value.coords.longitude.toString();
          router.push({
            path: '/coupon/near-current-location-list',
            query: form.searchForm
          });
        }).catch((error) => {
          switch (error.code) {
            case 1:
              alert("位置情報取得が許可されていません\n位置情報を取得する場合は位置情報取得設定を有効にしてください");
              break;
            case 2:
              // ヘッダーからはスマホ検索できないためAndroid Yappli対策はしない
              alert('位置情報取得に失敗しました');
              break;
            case 3:
              alert('位置情報取得処理がタイムアウトしました');
              break;
            default :
              alert('予期せぬエラーで位置情報の取得に失敗しました');
              break;
          }
        });
      } else {
        router.push({
          path: '/coupon/list',
          query: form.searchForm
        });
      }
      if (menu === 'sp') {
        menuToggle();
      }
    };

    const route = useRoute();
    watch(route, (to) => {
      if (to.path === '/coupon/list' || to.path === '/coupon/near-current-location-list') {
        form.searchForm.keyword = to.query.keyword || '';
        form.searchForm.category = to.query.category || '';
        form.searchForm.pref = to.query.pref || '';
        form.searchForm.latitude = to.query.latitude || '';
        form.searchForm.longitude = to.query.longitude || '';
      } else {
        form.searchForm.keyword = '';
      }
    });

    // 履歴画面に戻る
    const pageBack = (): void => {
      history.back();
    };

    return {
      form,
      masterStore,
      categories,
      menuToggle,
      search,
      pageBack,
      ...toRefs(data)
    };
  }
});
